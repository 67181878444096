<template>
  <div class="relative flex items-center cursor-pointer">
    <div class="flex h-[18px] items-center">
      <input
        :id="`${checkboxName}-checkbox`"
        v-model="model"
        :checked="checked"
        :aria-describedby="`${checkboxName}-checkbox`"
        type="checkbox"
        @change="onChange"
      />
    </div>
    <slot></slot>
    <div class="ml-[8px] text-sm leading-6">
      <label
        :for="`${checkboxName}-checkbox`"
        class="font-medium text-gray-900 cursor-pointer"
        :class="labelClass"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  checked: {
    type: Boolean,
    default: false
  },
  labelClass: {
    type: String,
    default: ''
  }
})

const checkboxName = computed(() => props.name || props.label)

const onChange = event => {
  emits('onChange', event.currentTarget.checked)
}

const emits = defineEmits(['update:modelValue', 'onChange'])
const model = computed({
  get: () => {
    return props.modelValue
  },
  set: v => {
    emits('update:modelValue', v)
  }
})
</script>

<style lang="postcss" scoped>
input {
  @apply h-[18px] w-[18px] rounded border-gray-mud text-green-ibis focus:ring-transparent focus:ring-offset-0 focus:ring-0 cursor-pointer bg-transparent;
}
</style>
